import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import WacLogo from './WacLogo';


const useStyles = makeStyles( (theme) => ({
  main: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary,
    background: 'linear-gradient(135deg,#55098e,#b1017b)',
  },
  formContainer: {
    background: '#FFF',
    borderRadius: '15px',
    padding: '2rem',
  },
  logoContainer: {
    width: '100%',
  },
  loginContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textInput: {
    margin: '1rem 0',
  },
  errorContainer: {
    borderRadius: '15px',
    color: '#FFF',
    marginTop: '2rem',
    backgroundColor: '#380340',
    padding: '1.2rem',
    fontSize: '1.2rem'
  }
}));


const SignIn = props => {

  const classes = useStyles();
  const hasError = props.error;

  return (
    <div className={classes.main}>
      <Container maxWidth="sm">
        <div className={classes.logoContainer} >
          <WacLogo />
        </div>
      <div className={classes.formContainer}>
        <h1>Login</h1>
        <form className={classes.loginContainer}>
          <TextField
            id="outlined-basic"
            label="User name"
            variant="outlined"
            value={props.uid}
            className={classes.textInput}
            onChange={event => props.inputChanged(event, 'username', 'signin')}
          />
          <TextField
            id="outlined-basic"
            label="Password"
            variant="outlined"
            type="password"
            className={classes.textInput}
            value={props.pwd}
            onChange={event => props.inputChanged(event, 'password', 'signin')}
          />
          <Button variant="contained" color="primary" onClick={props.onUserClick}>Login</Button>
        </form>
      </div>
        {hasError &&
          <div className={classes.errorContainer}>{props.error}</div>
        }
      </Container>
    </div>
  );
};

export default SignIn;
