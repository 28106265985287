import React, {Fragment, useState, useEffect} from 'react';
import { useStore } from 'react-redux'
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';

import SingleSite from '../SingleSite';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 900,
		marginTop: '20px',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));


function ScrapeForm() {
	const [data, setData] = useState({payload:''});
	const [showLoading, setShowLoading] = useState(false);
	const [query, setQuery] = useState({ url : ''});

  const store = useStore();
  let id = store.getState().id;

	useEffect( () => {

	}, [query, data]);


  const handleSubmit = (event) => {
    event.preventDefault();

		setShowLoading(true);

    const data = {
      url: query.url,
      id: parseInt(id),
    };
    console.log(data);

		let axiosConfig = {
		  headers: {
		      'Content-Type': 'application/json',
		  }
		};

    axios.post(process.env.REACT_APP_API_URL + '/api/scrape/single',  data, axiosConfig )
      .then(res => {
				setShowLoading(false);
				setData({
          payload: res.data
				})
      })
  }


	const classes = useStyles();

	return(
		<Fragment>
    <Paper variant="outlined" component="form" className={classes.root} onSubmit={handleSubmit} >
      <InputBase
        className={classes.input}
        placeholder="Insert website url"
        inputProps={{ 'aria-label': 'Insert website url' }}
        type="url"
					value={query.url}
          autoComplete="on"
					onChange={ event => setQuery({url: event.target.value})}
      />
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton color="primary" className={classes.iconButton} aria-label="directions" onClick={handleSubmit}>
        <DirectionsIcon />
      </IconButton>
    </Paper>


			{showLoading &&
				<div>loading</div>
			}
			{ data.payload &&
        <SingleSite data={data.payload} />
			}

		</Fragment>
	)

}

export default ScrapeForm;
