import React, {Fragment, useState} from 'react';
import { useStore } from 'react-redux'
import axios from 'axios';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';


function FileList(props) {
	const [file, setFile] = useState({file: null});
  const store = useStore();
  let id = store.getState().id;


	const onFormSubmit = (event) => {
		event.preventDefault();
		fileUpload(file.file);
	}

	const fileUpload = (file) => {
		let data = new FormData();
		data.append('csv', file);
    data.append('id', id);
		axios
			.post(process.env.REACT_APP_API_URL + '/api/uploads', data, {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			})
			.then(response => {
        console.log(response);
        props.fetchData();
			});
	}

  const deleteFile = (file, fileId) => {
    console.log(file);
    console.log(fileId);
    axios.delete( process.env.REACT_APP_API_URL + '/api/uploads/' + id, 
      { data: {id: fileId, name: file} })
      .then( response => {
        console.log(response);
        props.fetchData();
      
    });
  }


	return(
    <Fragment>
     <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>

	            	<form onSubmit={onFormSubmit} style={{display: 'inline-block'}}>
	            		<input  type="file" accept=".csv" onChange={e => setFile({file: e.target.files[0]})} />
	            		<Button variant="contained" color="primary" type="submit">Upload CSV</Button>
	            	</form>

	            	<Button style={{marginLeft: '20px'}} 
                  variant="contained" 
                  color="primary" 
                  onClick={props.fetchData} >
                    Refresh file list
                 </Button>

                </TableCell>
                <TableCell align="right">Url count</TableCell>
                <TableCell align="right">Uploaded</TableCell>
                <TableCell align="right"></TableCell>
              </TableRow>
            </TableHead>
          <TableBody>
          {props.files.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                {row.name}
                </TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right"></TableCell>
                <TableCell align="right">
                  <Button onClick={ () => deleteFile(row.name, row.id)}>
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
          ))}
          </TableBody>
          </Table>
      </TableContainer>
      </Fragment>
	);


}

export default FileList;
