import React from 'react';
import { makeStyles } from '@material-ui/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';

import { SidebarNav, UserProfile } from './components';

const drawerWidth = 300;

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2)
  },
  drawerPaper: {
    marginTop: theme.topBar.height,
    width: drawerWidth,
    backgroundColor: theme.palette.white,
    zIndex: theme.zIndex.drawer + 1,
    borderRight: 'unset',
  },
}));




const Sidebar = props => {

	const classes = useStyles();

	return(
    <nav>
  	<Drawer 
		 	className={classes.drawer}
		 	classes={{ paper: classes.drawerPaper}}           
       variant="permanent"
     >
        <UserProfile />
          <Divider />
    			<SidebarNav />
    </Drawer>
    </nav>

	);
}

export default Sidebar;
