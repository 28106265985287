import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension';
import jwt_decode from "jwt-decode";


const USER_STATE = {
  id: '',
  userName: '',
  email: '',
  token: '',
  orderType: 'BULK',
  alertText: null,
  isLoading: false,
};



export const user = (state = USER_STATE, action) => {
  let updatedUser = null;
  switch (action.type) {
    case 'USER_SIGNIN_SUCCESS':
      console.log('login succuess');
      const dataToken = action.dataToken;
      const decodedToken = jwt_decode(dataToken);
      updatedUser = {
        ...state,
        id: decodedToken.id,
        email: decodedToken.email,
        userName: decodedToken.username,
        token: dataToken,
        alertText: null,
        orderType: USER_STATE.orderType, //decodedToken.orderType,
        isLoading: false,
      }

      return updatedUser;
    case 'FETCH_USER_SIGNED_IN':
      return state;
    case 'SET_LOADING':
      const loading = {
        ...state,
        isLoading: action.loading
      };
      return loading;
    case 'LOGIN_FAILED':
      updatedUser = {
      ...state,
        alertText: action.msg
      }
      return updatedUser;
    case 'LOGOUT_USER':
      localStorage.removeItem("token")
      return USER_STATE;
    default:
      return state;
  }
};


const middleware = [thunk];


export const store = createStore(user, USER_STATE, composeWithDevTools(applyMiddleware(...middleware)))