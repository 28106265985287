import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './AxiosConfiguration';
import {store} from './reducers/user.js';
import {Helmet} from 'react-helmet';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import './assets/css/index.css';
import Auth from './components/Auth/Auth';

import { Provider } from 'react-redux';

const browserHistory = createBrowserHistory();


function App()  {
    return (
    <>
      <Helmet>
          <link rel="icon" type="image/ico" href="/favicon.ico" sizes="16x16" />
          <title>WAC - Website Age Checker App</title>
          <meta name="description" content="Scan your websites" />
      </Helmet>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Router history={browserHistory}>
            <Auth />
          </Router>
        </ThemeProvider>
      </Provider>
    </>
    );
}
export default App;
