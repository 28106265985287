const axios = require('axios');


export const setUserLogin = user => ({
  type: 'LOGIN_USER',
  payload: user,
})


export const userSignin = (user) => async dispatch => {
    
    try{
        // eslint-disable-next-line no-unused-vars
        const res = axios.post(process.env.REACT_APP_WP_URL, user)
					.then( response => {
						console.log(response);
						const dataToken = response.data.data.jwt;
            localStorage.removeItem("token")
            localStorage.setItem("token", dataToken );
						dispatch({ type: 'USER_SIGNIN_SUCCESS', dataToken});
					})
          .catch(function(error)  {
            console.log(error.response.data.data.message);
            const errorMessage = error.response.data.data.message;
            dispatch({ type: 'LOGIN_FAILED', msg: errorMessage});
          })
    }
    catch(e){
			console.log(e);
    }

}
export const setLoading = () => ({
  type: 'SET_LOADING',
  loading: true,
})

export const getUserFetch = () => async dispatch => {
  try {
      // eslint-disable-next-line no-unused-vars
    const dataToken = localStorage.getItem('token');
    var request = new Request(process.env.REACT_APP_TOKEN_AUTH, {
      method: 'GET',
      headers: new Headers({
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + dataToken
      })
    })
    
    fetch(request).then(response => {
      response.json().then(results => {
      console.log(results);
      if (results.success) {
          dispatch({type: 'USER_SIGNIN_SUCCESS', dataToken}); 
      } else {
        dispatch({type: 'SET_LOADING', loading: false});
      }
      })
    }).catch(function (err) {
      dispatch({type: 'SET_LOADING', loading: false});
      console.log(err)
    })

    //dispatch({type: 'FETCH_USER_SIGNED_IN'}); 
  }
  catch(e) {
    dispatch({type: 'SET_LOADING', loading: false});
    console.log(e);
  }
  
}
export const logoutUser = () => ({
    type: 'LOGOUT_USER'
})
