import React  from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { AppBar } from '@material-ui/core';
import WacLogo from './WacLogo';




const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary,
    background: 'linear-gradient(135deg,#55098e,#b1017b)',
    height: theme.topBar.height,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 30px',
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));


const Topbar = props => {
 const { className } = props;
  const classes = useStyles();

  return (
    <AppBar
      className={clsx(classes.root, className)}
    >
			<WacLogo />
    </AppBar>
  );	

}

export default Topbar;
