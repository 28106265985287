import React from 'react';
import { makeStyles } from '@material-ui/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle'; 
import { useStore } from 'react-redux'


const useStyles = makeStyles({
 profileImage: {
  width: 150,
  height: 150,
  display: 'block',
  borderRadius: '50%',
 },
 container: {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: 20,
 },
 profileInfo: {
  listStyleType: 'none',
  margin: 0,
  padding:0,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  marginTop: 10,
  marginBottom: 10,
 },
 profileInfoItem: {
  padding: 5,
 },
 profileIcon: {
   width: '100%',
   height: '100%',
   fill: '#bdbbbd',
 }

});

const UserProfile = (props) => {
  const styles = useStyles();
  const store = useStore();
  let email = store.getState().email;
  let userName = store.getState().userName;
  let userId = store.getState().id;
  let orderType = store.getState().orderType;

  return(
    <div className={styles.container}>
      <div className={styles.profileImage}>
        <AccountCircleIcon  classes={{root: styles.profileIcon}} />
      </div>
      <ul className={styles.profileInfo}>
        <li className={styles.profileInfoItem}>{userName}</li>
        <li className={styles.profileInfoItem}>{email}</li>
        <li className={styles.profileInfoItem}><b>Licence:</b> {orderType}</li>
      </ul>

    </div>
  )

}

export default UserProfile;
