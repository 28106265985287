import { createMuiTheme } from '@material-ui/core';

//import typography from './typography';
//import overrides from './overrides';

const theme = createMuiTheme({
  topBar: {
    height: 80,
  },
  zIndex: {
    appBar: 1100,
    drawer: 1200
  },
  palette: {
    primrayGradient: 'linear-gradient(135deg,#55098e,#b10129)',
    primary: {
      // Purple and green play nicely together.
      main: '#650a75',
    },
    secondary: {
      main: '#b1017b',
      dark: '#7b0457',
    },
  },
});

export default theme;
