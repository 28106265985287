import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {userSignin, getUserFetch, setLoading} from "../../actions/";
import SignIn from "./Signin";
import { RouteWithLayout } from '../';
import { Main as MainLayout } from '../../layouts';
import CircularProgress from '@material-ui/core/CircularProgress';

import {
  ScrapeSingle,
  ViewSites,
  CSVupload
} from '../../views';

import NotFoundPage from '../notFoundPage';

class Auth extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
     user : {
      email: "",
      password: "",
      username: "",
      name: ""
     },
    }
  }


  componentDidMount = () => {
    const hasToken = localStorage.getItem('token');
    if (hasToken) {
      this.props.setLoading();
      this.props.getUserFetch();
    }
  }

  onUserSignin = () => {
    //TODO: Validate inputs
    if(this.state.user.username === "" || this.state.user.password === ""){
      //TODO: ERROR REPORTING?
    }else{
      let userDets = {
        username: this.state.user.username,
        password: this.state.user.password
      };
      this.props.onUserSigninSubmit(userDets);
      let resetState = this.state.user;
      resetState.password = '';
      this.setState({user: resetState});
    }
  }

  onInputChange = (event, forField, formFor) => {
     let tempUser = this.state.user;
     tempUser[forField] = event.target.value;
     this.setState({user: tempUser});
  }

  render() {
    const orderType = this.props.orderType;
    const isLoading = this.props.isLoading;
    const localLogin = this.props.token;

    if (!localLogin && isLoading) {
      return (
      <React.Fragment>
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
        <CircularProgress />
          <p style={{marginTop: '1rem'}}>Loading...</p>
        </div>
      </React.Fragment>
      )
    }

    if (!localLogin) {
      return(
      <React.Fragment>
          <Route path="/" render={() => <SignIn
            uid={this.state.user.username} 
            pwd={this.state.user.password} 
            error={this.props.alertText}
            onUserClick={this.onUserSignin} 
            inputChanged={this.onInputChange} />
            } 
          />
      </React.Fragment>
      )
    }
    return (
         <Switch>
           <Redirect
             exact
             from="/"
             to="/check-age"
           />
           <RouteWithLayout
             component={ScrapeSingle}
             exact
             layout={MainLayout}
             path="/check-age"
           />
           {
            <RouteWithLayout
              component={CSVupload}
              exact
              layout={MainLayout}
              path="/bulk-check"
            />
            }
           <RouteWithLayout
             component={ViewSites}
             exact
             layout={MainLayout}
             path="/browse-checks"
           />
           <RouteWithLayout
             component={NotFoundPage}
             exact
             layout={MainLayout}
           />
         </Switch>
    )
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    onUserSigninSubmit: user => { dispatch(userSignin(user)) },
    getUserFetch: () => {dispatch(getUserFetch())},
    setLoading: () => {dispatch(setLoading())}
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Auth);
