import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux'
import { NavLink as RouterLink, useLocation  } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {  Link } from '@material-ui/core';

import {useStore} from 'react-redux';

const CustomRouterLink = forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{ flexGrow: 1 }}
  >
    <RouterLink {...props} />
  </div>
));



const useStyles = makeStyles(theme => ({
	listText: {
	},
  link: {
    padding: '20px',
    display: 'block',
    fontSize: '1.2rem',
    "&:hover": {
      backgroundColor: '#f8f8f8',
      textDecoration: 'none',
      fontWeight: 'bold',

    },
  },
  linkActive: {
    padding: '20px',
    display: 'block',
    fontSize: '1.2rem',
    fontWeight: 'bold',
    "&:hover": {
      backgroundColor: '#f8f8f8',
      textDecoration: 'none',
      fontWeight: 'bold',

    },
  }
}));


const SidebarNav = (props) => {
	const classes = useStyles();
  const store = useStore();
  const orderType = store.getState().orderType;
  const dispatch = useDispatch();

  const location = useLocation(); 

  const logout = (event) => {
     // Remove local storage token and reset state
     localStorage.removeItem("token");
     dispatch({ type: 'LOGOUT_USER' });
  }


  return(
    <ul>
      <li>
         <Link
           classes={{ root: (location.pathname !== '/check-age') ? classes.link : classes.linkActive}} 
           component={CustomRouterLink}
           to="/check-age"
         >
        Check Age
			 	</Link>
      </li>
      {
      <li>
         <Link
           classes={{ root: (location.pathname !== '/bulk-check') ? classes.link : classes.linkActive}} 
           component={CustomRouterLink}
           to="/bulk-check"
         >
        Bulk Check
			 	</Link>
      </li>
      }
      <li>
         <Link
           classes={{ root: (location.pathname !== '/browse-check') ? classes.link : classes.linkActive}} 
           component={CustomRouterLink}
           to="/browse-checks"
         >
        Browse Checks
			 	</Link>
      </li>
      <li>
         <Link
           onClick={event => logout(event)}
           classes={{ root: classes.link }} 
           component={CustomRouterLink}
           to="/"
         >
          Logout
          </Link>
      </li>
    </ul>
  
  );
}

export default SidebarNav;
