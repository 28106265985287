import React  from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';
import { makeStyles } from '@material-ui/styles';

const styles = makeStyles({
	svg: {
		position: 'absolute',
		width:'100%',
		height: '100%',
		left: 0,
		top: 0,
    fill: '#FFF',
	},
	container: {
		position: 'relative',
		width: '300px',
		height: '100px',
		display: 'block',
    margin: '1rem auto',
	},
})

const WacLogo = (props) => {
 	const style = styles();

  return(
	<div className={style.container}>
    <SvgIcon viewBox="0 0 353 146" className={style.svg}>
		<path d="M158.8,30.5l-26,88.1h-24.3L93.2,64.9l-16,53.6H52.9l-26-88.1h23.4l16.1,56.2l16.9-56.2h20.8l16.1,56.7l16.8-56.7H158.8z"
			/>
		<path d="M208.7,101.4h-33.9l-6.3,17.1h-23.1l35.4-88.1h22.3l35.5,88.1H215L208.7,101.4z M202.1,83.1l-10.3-28.2l-10.3,28.2H202.1z
			"/>
		<path d="M281.2,120.4c-10.4,0-20.2-3.5-28.4-10c-2.7-2.2-5.2-4.7-7.4-7.4c-5.3-6.7-8.6-14.7-9.5-23.2c-1.4-12.1,2.1-24.1,9.7-33.6
			c7.6-9.5,18.5-15.5,30.6-16.9c1.7-0.2,3.5-0.3,5.2-0.3c10.3,0,20,3.4,28.2,9.8l5.7,4.5l-13.7,13.3l-4.3-3.2
			c-4.6-3.5-10.1-5.3-15.8-5.3c-1,0-2,0.1-3,0.2c-7.1,0.8-13.4,4.3-17.9,9.9c-4.4,5.6-6.5,12.5-5.7,19.6c0.6,5,2.5,9.7,5.5,13.5
			c1.3,1.6,2.7,3,4.3,4.3c4.8,3.8,10.5,5.8,16.6,5.8c1,0,2-0.1,3-0.2c7.1-0.8,13.4-4.3,17.9-9.9l4-5L321,98.3l-4,5
			c-7.6,9.5-18.5,15.5-30.6,16.9C284.7,120.3,283,120.4,281.2,120.4z"/>
	<path d="M279.8,59.1c-0.9,0-1.7,0.8-1.7,1.7v16.9l15.4,8c0.2,0.1,0.5,0.2,0.8,0.2c0.6,0,1.2-0.3,1.5-0.9c0.4-0.8,0.1-1.9-0.7-2.3
		l-13.6-7V60.8C281.5,59.8,280.7,59.1,279.8,59.1z"/>
	<polygon points="321.1,62.9 295.1,62.1 320.6,36.9 	"/>

    </SvgIcon>
		</div>
  );

}

export default WacLogo;
