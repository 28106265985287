import React from 'react';
import { ScrapeForm } from './components';

const ScrapeSingle = (props) => {
  
  return (
    <div>
			<h1>Analyze single website</h1>
      <ScrapeForm />
    </div>
  )

}

export default ScrapeSingle;
