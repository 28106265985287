import React, {Fragment, useState, useEffect} from 'react';
import axios  from 'axios';
import {FileList, CsvGroup} from './components';
import { useStore } from 'react-redux'

function CSVupload() {

	const [payload, setPayload] = useState({files : []});
  const store = useStore();
  let id = store.getState().id;

	useEffect(() => {
    const fetchData = async () => {
      const result = await axios(
         process.env.REACT_APP_API_URL + '/api/uploads/' + id,
      );
      setPayload({files:result.data});
    };
 
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

  const fetchData = async () => {
    const result = await axios(
       process.env.REACT_APP_API_URL + '/api/uploads/' + id,
    );
    setPayload({files:result.data});
  };

	return(
		<Fragment>
		<h1 style={{marginBottom: '1rem'}}>CSV import</h1>

    <CsvGroup 
      files={payload.files}
    />


		<FileList
      files={payload.files}
      fetchData={fetchData}
    />


		</Fragment>
	)
}


export default CSVupload;
