import React, {Fragment, useState, useEffect} from 'react';
import { useStore } from 'react-redux'
import { SitesList } from './components';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    paper: {
          position: 'absolute',
          width: 400,
          top: '45%',
          left: '45%',
          backgroundColor: '#FFF',
          outline: 'unset',
          boxShadow: theme.shadows[5],
          padding: theme.spacing(2, 4, 3),
        },
}));

function ViewSites() {
  const classes = useStyles();
  const store = useStore();
  let id = store.getState().id;

	const [data, setData] = useState({sitesData: []});
	const [showLoading, setShowLoading] = useState(true);
  const [groups, setGroups] = useState([]);
  const [filterGroup, setFilterGroup] = useState('all');
  const [edit, setEdit ] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState({name: '', _id: ''});




  useEffect(() => {


  const fetchGroups = async () => {
    const groups = await axios.get(
      process.env.REACT_APP_API_URL + '/api/groups',
      {params: { ID: id }}
    ).then(function (response) {
          // handle success
           console.log(response);
      return response;
             })
    setGroups(groups.data);
  }

  const fetchData = async () => {
    const result = await axios.get(
       process.env.REACT_APP_API_URL + '/api/sites/group/' + filterGroup,
      {params: { ID: id }}
    ).then( function(response) {
      console.log(response);
      return response;
    });
    setData({sitesData: result.data});
  };



    fetchData();
    fetchGroups();
		setShowLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterGroup]);


  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    setEdit(!edit);
  }

  const handleRemoveGroup = (id) => {
    setSelectedGroup(id);
    setOpen(true);
  }

  const handleFilterSites = (groupId) => {
    console.log(groupId);
    setFilterGroup(groupId);
  }

  const removeGroup = (group) => {

    axios.delete(
    process.env.REACT_APP_API_URL + '/api/groups/remove/'
    ,{ data: { groupId: group._id, userId: id, removeAll: false} }).then( function(response) {
      console.log(response.data);
      setFilterGroup('all');
    })

  }
  const removeGroupSites = (group) => {
    console.log(group);
    axios.delete(
    process.env.REACT_APP_API_URL + '/api/groups/remove/'
    ,{ data: { groupId: group._id, removeAll: true} }).then( function(response) {
      setFilterGroup('all');
    })
  }

  const body = (
    <div className={classes.paper}>
      <h2 id="simple-modal-title">{selectedGroup.name}</h2>
      <p id="simple-modal-description" style={{margin: '10px 0'}}>
        Remove selected groups with options below. You can remove all the sites in the group and other groups or remove the group and leave the sites to all listing.
      </p>
       <Button size="small" style={{margin: '5px'}} variant="contained" onClick={event => removeGroupSites(selectedGroup)}>
         All sites in this group
       </Button>
       <Button size="small" style={{margin: '5px'}} variant="contained" onClick={event => removeGroup(selectedGroup)}>
         Leave sites
       </Button>
    </div>
  );



  return(
		<Fragment>

			{showLoading &&
				<div>loading</div>
			}
      <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center',marginBottom: '20px'}}>
        <ul style={{display: 'flex', flexWrap: 'wrap', listStyleType:  'none', }}>
        <li>
          <Button style={{margin: '0px 10px'}} variant="contained" color="primary" onClick={event => handleFilterSites('all')}>
            All
          </Button>
        </li>
       {groups.map((group) =>
        <li>
         {!edit ? 
          <Button style={{margin: '0px 10px'}} variant="contained" color="primary" onClick={event => handleFilterSites(group._id)}>
            {group.name}
          </Button>
           :
          <Button style={{margin: '0px 10px'}} variant="outlined" color="primary" onClick={event => handleRemoveGroup(group)}>
            {group.name}
          </Button>
         }
        </li>
       )}
       </ul>
          <Button style={{margin: '0px 10px'}} variant="contained" color="secondary" onClick={handleEdit}>
            Remove group
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {body}
          </Modal>
    </div>


			{data.sitesData.length > 0 &&
				<SitesList data={data.sitesData} />
			}
    </Fragment>
  )
}

export default ViewSites;
