import React, {Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent'; 
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles({
    root: {
          minWidth: 275,
          marginTop: '30px',
          maxWidth: 900
        },
    title: {
          fontSize: 14,
        },
		cardTop: {
					display: 'flex',
					justifyContent: 'space-between',
					width: '100%',
					marginBottom: '10px',
		},
    content: {
          marginTop: '20px',
					marginButtom: '20px',
        },
});


const ImageInfo = (props) => {

  const classes = useStyles();
	console.log(props);

	let date = props.image.date;
	date = new Date(date);
  date = `${date.getMonth()}`.padStart(2, '0') + '-' + date.getFullYear();


	return(
		<div className={classes.content}>
     <Typography color="textPrimary" gutterBottom>
      {props.text}: <b>{date}</b>
    </Typography>
     <Typography color="textSecondary" gutterBottom>
      Source: {props.image.source}
    </Typography>
		</div>
	)

}

function SingleSite(props){

  const classes = useStyles();

	let createdAt = props.data.createdAt;
	createdAt = new Date(createdAt).toISOString().split('T')[0];

	let updatedAt = props.data.updatedAt;
	updatedAt = new Date(createdAt).toISOString().split('T')[0];

  /* eslint-disable no-unused-vars */
  let latest = '';
  let oldest = '';

  if (props.data.images.length > 1) {
    latest = props.data.images[props.data.images.length -1];
    oldest = props.data.images[0].date;
  }
  /* eslint-enable no-unused-vars */


  return(
    <Card className={classes.root} variant="outlined">
      <CardContent>
				<div className={classes.cardTop}>
      	 <Typography className={classes.title} color="textSecondary" gutterBottom>
          {props.data.url}
        </Typography>
      	 <Typography className={classes.title} color="textSecondary" gutterBottom>
          Earliest Scan: {createdAt}
        </Typography>
      	 <Typography className={classes.title} color="textSecondary" gutterBottom>
          Recent Scan: {updatedAt}
        </Typography>
				</div>

				<Divider />

				{props.data.images.length > 0 &&
				<Fragment>
        <ImageInfo 
					image={props.data.images[0]}
					text="Creation Date Estimation"
				/>
				<ImageInfo 
					image={props.data.images[props.data.images.length -1]}
					text="Latest Update Estimation"
				/>
				</Fragment>
				}

      </CardContent>
    </Card>
  )
}


export default SingleSite;
