import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Sidebar, Topbar } from './components';

const useStyles = makeStyles( () => ({
  root: {
    paddingTop: 56,
    height: '100%',
  },
  shiftContent: {
    paddingLeft: 300,
  },
  content: {
    height: '100%',
    padding: '100px 50px 50px 50px',
    background: '#f3f3f3',
    minHeight: '100vh',
  }
}));


const Main = props => {
  const { children } = props;

  const classes = useStyles();


	return(
		<div className={classes.shiftContent}>
      <Sidebar />
			<Topbar  />

      <main className={classes.content}>
        {children}
      </main>

		</div>

	);
}

export default Main;
