import React, {Fragment, useState } from 'react';
import { useStore } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  group: {
    margin: theme.spacing(1),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
	container: {
		marginBottom: '50px',
		padding: '10px 5px',
		display: 'flex',
		alignItems: 'center'
	},
  progress: {
		width: '100%',
		justifyContent: 'center',
		alignItems: 'center',
		marginBottom: '30px',
    display: 'flex',
  },
}));


function CsvGroup(props) {

	const classes = useStyles();
	const [csv, setCsv] = useState('');
	const [group, setGroup] = useState('');
	const [scraping, setScraping] = useState(false);

  const store = useStore();
  let id = store.getState().id;

	const handleCsv = (event) => {
		setCsv(event.target.value);
    console.log(event.target.value);
	}

	const handleStart = (event) => {
		event.preventDefault();
		if (!csv && !group) {
			console.log('dont start');
			return;
		}

    const data = {
      file: csv,
      group: group,
      userId: id,
    }

    console.log(data);
		axios.post(process.env.REACT_APP_API_URL + '/api/scrape/group/', data, {
				headers: {
					'Content-Type': 'application/json',
				},
			})
			.then(response => {
        console.log(response);
        setScraping(true);
			});
		
	}

	

  return (
		<Fragment>
    <Paper className={classes.container} variant="outlined">
		 <TextField id="outlined-basic" 
				label="Group" 
				variant="outlined"
				value={group}  
				className={classes.group}
				onChange={event => setGroup(event.target.value)}
			/>
     <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="demo-simple-select-outlined-label">CSV</InputLabel>
        <Select
          value={csv}
          onChange={handleCsv}
          label="CSV"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
					{ props.files.map( (item, index) => (
          	<MenuItem key={index} value={item}>{item.name}</MenuItem>

					))}
        </Select>
      </FormControl>
		
	    <Button style={{marginLeft: '20px'}} 
        variant="contained" 
        color="primary" 
				onClick={handleStart}
        >
         Start scanning
       </Button>
    </Paper>
		{ scraping &&
		<div className={classes.progress}>
      <CircularProgress color="secondary" />
    </div>
		}
		</Fragment>
  );
}


export default CsvGroup;
